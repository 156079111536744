import { SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { OrtoScene, PotreeDisplayModes, Project } from '../../../../types';
import { authTokenHeader } from '../../../../utils/helper';

export type UpdateAnnotationArgs = {
  mode: PotreeDisplayModes;
  token: string | null;
  setProject: React.Dispatch<SetStateAction<Project | null>>;
};

/**
 * This is a HOPEFULLY TEMPORARY helper method for adding, updating and deleting annotations.
 * All annotation operations rely on updating the scene, to which an annotation currently has to belong.
 */
export const updateScene = async (
  scene: OrtoScene,
  args: UpdateAnnotationArgs
) => {
  const { mode, token, setProject } = args;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${scene.id}`,
      {
        method: 'POST',
        headers: authTokenHeader(token),
        body: JSON.stringify(scene),
      }
    );

    const updatedScene = await response.json();

    if (!updatedScene || !response.ok) {
      toast.error(`Updating scene '${updatedScene}' failed.`);
      return;
    }

    toast.success(`Scene '${scene.label}' updated.`);

    setProject((prevProject: any) => ({
      ...prevProject,
      field_scenes: {
        ...prevProject.field_scenes,
        [mode]: {
          ...prevProject.field_scenes[mode],
          scenes: prevProject.field_scenes[mode].scenes.map((s: OrtoScene) =>
            s.id === scene.id ? updatedScene : s
          ),
        },
      },
    }));
  } catch (error: any) {
    console.error('API request failed:', error.message);
    throw error;
  }
};
