import { useTranslation } from 'react-i18next';

import { cn } from '../../../utils/helper';

interface ToolsSectionProps {
  menuLabel: string;
  children: React.ReactNode;
  classNames?: string;
}

const ToolsSection: React.FC<ToolsSectionProps> = ({
  menuLabel,
  children,
  classNames,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-1 transition-all',
        classNames
      )}
    >
      <p className="whitespace-nowrap text-[12px] capitalize text-secondary-foreground">
        {menuLabel && t(menuLabel)}
      </p>
      <div
        className={
          'flex flex-row gap-1 overflow-hidden rounded-lg bg-foreground px-1 shadow-2xl'
        }
      >
        {children}
      </div>
    </div>
  );
};

export default ToolsSection;
