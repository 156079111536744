import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULTS } from '../../constants/defaults';
import { Project } from '../../types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../shadcn-ui/table';
import ProjectMenu from './ProjectMenu';

const ProjectsList = ({ projects }: { projects: Project[] }) => {
  const { t } = useTranslation();

  const tableHeadContent = (
    <TableRow className="border-none hover:bg-inherit">
      <TableHead className="text-white">#</TableHead>
      <TableHead className="capitalize">{t('project')}</TableHead>
      <TableHead className="capitalize">{t('location')}</TableHead>
      <TableHead className="capitalize">{t('created')}</TableHead>
      <TableHead className="capitalize">{t('updated')}</TableHead>
      <TableHead></TableHead>
    </TableRow>
  );

  let tableBodyContent;
  if (!projects || projects.length <= 0) {
    tableBodyContent = (
      <TableRow className="border-white/10 hover:bg-inherit">
        <TableCell>No results found.</TableCell>
      </TableRow>
    );
  } else {
    tableBodyContent = projects.map((project: Project, index: number) => (
      <TableRow
        key={`project_${project.id}_${index}`}
        className="border-b border-white/10 hover:bg-inherit"
      >
        <TableCell className="w-2 font-medium">{index + 1}</TableCell>

        <TableCell className="hover:underline">
          <Link to={`/project/${project.id}`} className="flex">
            <span className="text-foreground">{project.label}</span>
          </Link>
        </TableCell>

        <TableCell>{project.field_location || '-'}</TableCell>

        <TableCell>
          {dayjs(Number(project.created) * 1000).format(
            DEFAULTS.formatDateTime
          )}
        </TableCell>

        <TableCell>
          {dayjs(Number(project.changed) * 1000).format(
            DEFAULTS.formatDateTime
          )}
        </TableCell>

        <TableCell className="w-2">
          <ProjectMenu projectID={project.id} />
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <Table className="w-full text-left">
      <TableHeader className="text-secondary">{tableHeadContent}</TableHeader>
      <TableBody>{tableBodyContent}</TableBody>
    </Table>
  );
};

export default ProjectsList;
