import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import './RichTextEditor.css';

const modules = {
  toolbar: [
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

// prettier-ignore
const formats = [
  'bold', 'italic',
  'list', 'list',
  'link', 'image',
];

type RichTextEditorProps = {
  value: string | undefined | null;
  onChange: any;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {
  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      value={value || ''}
      onChange={onChange}
    />
  );
};

export default RichTextEditor;
