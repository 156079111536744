import { useTranslation } from 'react-i18next';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../shadcn-ui/select';

const locales: {
  [key: string]: {
    title: string;
  };
} = {
  en: { title: 'EN' },
  de: { title: 'DE' },
};

const LanguageSwitcher = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation();

  return (
    <Select onValueChange={i18n.changeLanguage} value={i18n.language}>
      <SelectTrigger className={className}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent className={className}>
        {Object.keys(locales).map((locale) => (
          <SelectItem value={locale} key={`language_switcher_item_${locale}`}>
            {locales[locale].title}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default LanguageSwitcher;
