import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

import { POTREE_DISPLAY_MODES } from '../../../constants/potree';
import { useAnnotationsStore } from '../../../context/zustand/annotationsStore';
import { useSlidesStore } from '../../../context/zustand/slidesStore';
import { usePotree } from '../../../hooks/usePotree';
import { useToggle } from '../../../hooks/useToggle';
import { OrtoSceneHelper } from '../../../utils/OrtoSceneHelper';
import { potreeTools } from '../../../utils/potreeTools';
import Tools from '../shared/Tools';
import Wrapper from '../shared/Wrapper';

const TabsBar = () => {
  const { t } = useTranslation();
  const { project, potreeViewer, setProjectPcs } = usePotree();
  const { potreeDisplayMode, setPotreeDisplayMode } = useToggle();

  const activeSlide = useSlidesStore((state) => state.activeSlide);
  const setActiveSlide = useSlidesStore((state) => state.setActiveSlide);

  const setAnnotations = useAnnotationsStore((state) => state.setAnnotations);

  // Set display mode related default configs for camera prospective
  useEffect(() => {
    if (!potreeViewer) return;

    // Find action method showModel, showGrundriss or showSchnitt
    // depending on currently active display mode
    const activeDisplayModeAction = POTREE_DISPLAY_MODES.find(
      ({ value }) => value === potreeDisplayMode
    )?.action;

    // Set display mode related default configs for camera prospective
    potreeTools[activeDisplayModeAction as string](potreeViewer);
  }, [potreeDisplayMode, potreeViewer]);

  // On project load or potree display mode change, set
  // first scene of display mode as active
  useEffect(() => {
    if (!project || !potreeViewer) return;

    // On entering a new display mode or enteting the project for the first time, activate the first slide
    if (
      activeSlide.field_scene_type !== potreeDisplayMode ||
      activeSlide.id === '-1'
    ) {
      // Scenes of currently active display mode
      const activeDisplayModeScenes =
        project?.field_scenes[potreeDisplayMode]?.scenes;

      // Check if there is a first scene in currently active display mode scenes
      const firstScene = activeDisplayModeScenes?.length
        ? activeDisplayModeScenes[0]
        : null;

      if (firstScene) {
        OrtoSceneHelper.setScene(potreeViewer, firstScene, setProjectPcs);
        setActiveSlide({
          id: firstScene.id,
          field_scene_type: potreeDisplayMode,
        });
        setAnnotations(potreeViewer.scene.annotations.children);
      }
    }
  }, [
    project,
    potreeDisplayMode,
    potreeViewer,
    setProjectPcs,
    activeSlide,
    setActiveSlide,
    setAnnotations,
  ]);

  if (!potreeViewer) return null;

  return (
    <Wrapper
      elementType="section"
      classNames="z-10 absolute bottom-6 left-1/2 transform -translate-x-1/2"
    >
      <div className="flex flex-row gap-3">
        <ul className="flex flex-row items-center gap-3 rounded-lg bg-[#F5F5F5] p-2">
          {POTREE_DISPLAY_MODES.map((displayMode) => {
            const label = POTREE_DISPLAY_MODES.find(
              ({ value }) => value === displayMode.value
            )?.value;

            return (
              <li
                key={uniqid()}
                onClick={() => setPotreeDisplayMode(displayMode.value)}
                className={`cursor-pointer rounded-lg px-4 py-2 capitalize transition ${
                  potreeDisplayMode === displayMode.value
                    ? 'bg-primary text-white'
                    : 'text-primary hover:bg-secondary hover:text-white'
                }`}
              >
                {t(`${label}`)}
              </li>
            );
          })}
        </ul>

        <Tools />
      </div>
    </Wrapper>
  );
};

export default TabsBar;
