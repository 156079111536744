import { useTranslation } from 'react-i18next';
import { MdLogout } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import Button from '../shared/ButtonShadcn';
import Container from '../shared/Container';
import Heading from '../shared/Heading';
import LanguageSwitcher from '../shared/LanguageSwitcher';

interface AdminHeaderProps {
  activeProjects?: number;
  archiveProjects?: number;
}

const AdminHeader: React.FC<AdminHeaderProps> = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  if (!auth.token) return null;

  return (
    <header>
      <Container>
        <div className="flex h-16 flex-row items-center justify-between gap-4">
          <div className="flex flex-row items-center gap-4">
            <Heading title="projects" />

            {/* NOTE: adding projects is currently handled via drupal and this button should not be presented to the user, cf #33 */}
            {/* {location.pathname === "/dashboard" && (
              <Button
                icon={GoPlus}
                handleClick={() => navigate("/project/add")}
              />
            )} */}

            {location.pathname === '/project/add' && (
              <Button onClick={() => navigate('/dashboard')}>
                {t('cancel')}
              </Button>
            )}
          </div>

          <div className="flex w-full flex-row items-center justify-end gap-2">
            <LanguageSwitcher className="max-w-[100px] sm:max-w-[125px]" />

            <Button
              onClick={() => auth.logout({})}
              className="hidden capitalize sm:flex"
              variant="outline"
            >
              {t('logout')}
            </Button>

            <Button
              onClick={() => auth.logout({})}
              className="sm:hidden"
              variant="outline"
              size="icon"
            >
              <MdLogout />
            </Button>
          </div>

          {/* 
        <nav>
          <ul className="hidden sm:flex flex-row gap-6 items-center">
            {activeProjects && activeProjects > 0 ? (
              <li>Active ({activeProjects})</li>
            ) : null}
            {archiveProjects && archiveProjects > 0 ? (
              <li>Archive ({archiveProjects})</li>
            ) : null}
          </ul>
        </nav> 
        */}
        </div>
      </Container>
    </header>
  );
};

export default AdminHeader;
