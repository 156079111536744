import React, { ReactNode, createContext, useState } from 'react';

import Button from '../components/shared/Button';

interface ConfirmationContextProps {
  showConfirmation: (message: string, onConfirm: () => void) => void;
}

export const ConfirmationContext = createContext<
  ConfirmationContextProps | undefined
>(undefined);

export const ConfirmationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [confirmation, setConfirmation] = useState<{
    message: string;
    onConfirm: () => void;
  } | null>(null);

  const showConfirmation = (message: string, onConfirm: () => void) => {
    setConfirmation({ message, onConfirm });
  };

  const confirm = () => {
    confirmation?.onConfirm();
    setConfirmation(null);
  };

  const cancel = () => {
    setConfirmation(null);
  };

  return (
    <ConfirmationContext.Provider value={{ showConfirmation }}>
      {children}
      {confirmation && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 h-full w-full bg-backdrop/30 backdrop-blur-sm">
          <div className="fixed left-1/2 top-1/2 z-50 flex h-auto min-w-[300px] max-w-[600px] -translate-x-1/2 -translate-y-1/2 transform flex-col gap-4 overflow-auto rounded-lg bg-white p-8 text-primary shadow-xl">
            <p>{confirmation.message}</p>
            <div className="mt-8 flex flex-row justify-end gap-4">
              <Button
                handleClick={cancel}
                label="cancel"
                classNames="text-white bg-primary"
              />
              <Button
                handleClick={confirm}
                label="confirm"
                classNames="text-white bg-destructive"
              />
            </div>
          </div>
        </div>
      )}
    </ConfirmationContext.Provider>
  );
};
