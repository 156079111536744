import LanguageSwitcher from '../../shared/LanguageSwitcher';

const Shortcuts = () => {
  return (
    <div className="flex flex-col justify-between gap-4">
      <LanguageSwitcher className="w-full" />
    </div>
  );
};

export default Shortcuts;
