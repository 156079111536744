import { useEffect } from 'react';

import ProjectViewer from '../components/potree/project/ProjectViewer';
import Error from '../components/shared/Error';
import Loading from '../components/shared/Loading';
import { useSlidesStore } from '../context/zustand/slidesStore';
import { usePotree } from '../hooks/usePotree';

const Project = () => {
  const { project, loading, error } = usePotree();

  // Reset the active slide if the project changes (i.e. the user navigates to a different project)
  // NOTE: affects the SceneSlides component
  const resetActiveSlide = useSlidesStore((state) => state.resetActiveSlide);
  useEffect(() => {
    resetActiveSlide();
  }, [project?.id, resetActiveSlide]);

  if (loading) return <Loading theme="light" />;

  if (error) return <Error message={error.message} />;

  if (!project) return null;

  return <ProjectViewer />;
};

export default Project;
