import { ButtonProps, Button as ShadcnButton } from '../shadcn-ui/button';

const Button: React.FC<ButtonProps> = (props) => {
  const { variant, children } = props;
  return (
    <ShadcnButton {...props} variant={variant ?? 'default'}>
      {children}
    </ShadcnButton>
  );
};

export default Button;
