import { create } from 'zustand';

type KeyboardEventsState = 'enabled' | 'disabled';

type NavigationStateStoreProps = {
  keyboardEvents: KeyboardEventsState;
  enableKeyboardEvents: () => void;
  disableKeyboardEvents: () => void;
};

export const useNavigationStore = create<NavigationStateStoreProps>((set) => ({
  keyboardEvents: 'enabled',
  enableKeyboardEvents: () => set(() => ({ keyboardEvents: 'enabled' })),
  disableKeyboardEvents: () => set(() => ({ keyboardEvents: 'disabled' })),
}));
