import { create } from 'zustand';

import { AnnotationPotreeObject } from '../../types';

type AnnotationsStateStore = {
  annotations: AnnotationPotreeObject[];
  setAnnotations: (annotations: AnnotationPotreeObject[]) => void;
  resetAnnotations: () => void;
};

export const useAnnotationsStore = create<AnnotationsStateStore>((set) => ({
  annotations: [],
  setAnnotations: (annotations) => {
    set(() => ({ annotations: annotations }));
  },
  resetAnnotations: () => set({ annotations: [] }),
}));
