import { MapPin, Wallpaper } from 'lucide-react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RichText, SerializableAnnotation } from '../../../types';
import { contentfulRichText, toRounded } from '../../../utils/helper';
import { Button } from '../../shadcn-ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../shadcn-ui/dialog';
import { Input } from '../../shadcn-ui/input';
import { Separator } from '../../shadcn-ui/separator';
import RichTextEditor from '../../shared/RichTextEditor';

export type AnnotationEditorOnChange = (
  key: 'title' | 'description',
  value: string | RichText
) => void;

type AnnotationEditorProps = {
  annotation: Pick<SerializableAnnotation, 'title' | 'description'> &
    Partial<Pick<SerializableAnnotation, 'position' | 'cameraPosition'>>;
  onChange: AnnotationEditorOnChange;
  submitLabel: 'create' | 'update';
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};

const _formatNumbers = (n: number[] | undefined): string => {
  if (!n) {
    return '(-, -, -)';
  }
  const joined = n.map(toRounded).join(', ');
  return `(${joined})`;
};

const AnnotationEditor: React.FC<AnnotationEditorProps> = ({
  children,
  annotation,
  onChange,
  onSubmit,
  submitLabel,
  onCancel,
}) => {
  const { t } = useTranslation();

  const _setTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange('title', e.target.value);
    },
    [onChange]
  );

  const _setDescription = useCallback(
    (value: string) => {
      if (contentfulRichText(value)) {
        onChange('description', value);
      } else {
        onChange('description', '');
      }
    },
    [onChange]
  );

  const _location = useCallback(() => {
    return _formatNumbers(annotation.position);
  }, [annotation.position]);

  const _cameraPosition = useCallback(() => {
    return _formatNumbers(annotation.cameraPosition);
  }, [annotation.cameraPosition]);

  return (
    <Dialog>
      {/* Dialog trigger */}
      {children}

      {/* Dialog content - NOTE: className='light' enforces the light theme! */}
      <DialogContent className="light" aria-describedby="">
        <DialogHeader>
          <DialogTitle className="grid grid-cols-2 font-normal text-accent">
            <span className="flex gap-1">
              <MapPin size={16} /> <span>{_location()}</span>{' '}
            </span>
            <span className="flex gap-1">
              <Wallpaper size={16} /> <span>{_cameraPosition()}</span>
            </span>
          </DialogTitle>
          <Separator />
        </DialogHeader>

        <div className="grid gap-3 py-3">
          <Input
            value={annotation.title}
            onChange={_setTitle}
            placeholder={t('title')}
            className="bg-editor font-medium placeholder:capitalize placeholder:text-accent"
          />

          <RichTextEditor
            value={annotation.description}
            onChange={_setDescription}
          />
        </div>

        <DialogFooter className="flex gap-4">
          <DialogClose asChild>
            <Button onClick={onCancel} variant="outline" className="capitalize">
              {t('cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              onClick={onSubmit}
              className="bg-primary capitalize text-primary-foreground"
            >
              {t(submitLabel)}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AnnotationEditor;
