import { type ClassValue, clsx } from 'clsx';
import { jwtDecode } from 'jwt-decode';
import { twMerge } from 'tailwind-merge';

// Merge tailwind classes
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

/**
 * Helper function for "unescaping" rich text markup from Drupal.
 * The standard Drupal rich text editor does not have a button for creating an <a> tag, for example. Therefore
 * you would have to type the anchor tag (or any missing tag) yourself. These self-typed tags then get escaped
 * to &lt; and &gt;, which React fails to understand and render.
 *
 * @param htmlString An rich text string coming from Drupal
 * @returns Same string, but ready to be used in dangerouslySetInnerHTML
 */
export const unescapeHTML = (htmlString: string): string => {
  return htmlString.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
};

/**
 * Check if an htmlString has some 'real' content.
 * This function is necessary because a blank Quill.js editor has the value '<p><br></p>' and not an empty string
 */
export const contentfulRichText = (htmlString: string | undefined): boolean => {
  return (
    !!htmlString &&
    (htmlString.includes('<img') ||
      htmlString.replace(/<(.|\n)*?>/g, '').trim().length > 0)
  );
};

/**
 * Check if an htmlString has no real content.
 * This function is necessary because a blank Quill.js editor has the value '<p><br></p>' and not an empty string
 */
export const blankRichText = (htmlString: string | undefined): boolean => {
  return !contentfulRichText(htmlString);
};

/**
 * Round a floating point number to two digits
 */
export const toRounded = (num: number, precision: number = 2): number => {
  const factor = Math.pow(10, precision);
  return Math.round((num + Number.EPSILON) * factor) / factor;
};

// Check if the input array or object is empty
type ArrayOrObject = Array<unknown> | Record<string, unknown>;

export const isEmpty = (input: ArrayOrObject | string): boolean => {
  if (typeof input === 'string') return input.trim() === '';

  if (Array.isArray(input)) return input.length === 0;

  if (typeof input === 'object' && input !== null)
    return Object.keys(input).length === 0;

  return true;
};

// Get default options for Multicheckbox and Radio fields
export const getDefaultOptions = (
  data: any,
  labelKey: string,
  valueKey: string
) => {
  return data.map((item: any) => ({
    label: item[labelKey].toString(),
    value: item[valueKey],
  }));
};

// Auth/JWT token
export const authTokenHeader = (token: string | null) => {
  if (!token) {
    return;
  }

  return { Authorization: `Bearer ${token}` };
};

export const isJwtExpired = (token: string) => {
  if (token) {
    const now = Date.now();
    // Decode jwt to extract expiry timestamp
    const decodedToken = jwtDecode(token);
    // jwt token expiry timestamp in ms
    const tokenExpiry =
      decodedToken && decodedToken.exp ? decodedToken.exp * 1000 : null;

    return tokenExpiry && tokenExpiry < now;
  }

  return false;
};
