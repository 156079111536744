import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';

import { DEFAULTS } from '../constants/defaults';
import { PotreeDisplayModes } from '../types';

interface ToggleContextProps {
  // Left Sidebar
  isLeftSideMenuOpen: boolean;
  setIsLeftSideMenuOpen: Dispatch<SetStateAction<boolean>>;

  // Right Sidebar
  isRightSideMenuOpen: boolean;
  setIsRightSideMenuOpen: Dispatch<SetStateAction<boolean>>;

  // Left Sidebar Submenu Overlay (View Notes / Edit Notes)
  isOpenSubmenu: boolean;
  setIsOpenSubmenu: Dispatch<SetStateAction<boolean>>;

  // Left Sidebar Submenu Edit/View Note id
  editNoteFormId: string | null;
  setEditNoteFormId: Dispatch<SetStateAction<string | null>>;

  // Potree display mode
  potreeDisplayMode: PotreeDisplayModes;
  setPotreeDisplayMode: Dispatch<SetStateAction<PotreeDisplayModes>>;
}

export const ToggleContext = createContext<ToggleContextProps | undefined>(
  undefined
);

export const ToggleProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLeftSideMenuOpen, setIsLeftSideMenuOpen] = useState(false);
  const [isRightSideMenuOpen, setIsRightSideMenuOpen] = useState(false);
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const [editNoteFormId, setEditNoteFormId] = useState<string | null>(null);
  const [potreeDisplayMode, setPotreeDisplayMode] =
    useState<PotreeDisplayModes>(
      DEFAULTS.potreeDisplayTab as PotreeDisplayModes
    );

  return (
    <ToggleContext.Provider
      value={{
        isLeftSideMenuOpen,
        setIsLeftSideMenuOpen,
        isRightSideMenuOpen,
        setIsRightSideMenuOpen,
        isOpenSubmenu,
        setIsOpenSubmenu,
        editNoteFormId,
        setEditNoteFormId,
        potreeDisplayMode,
        setPotreeDisplayMode,
      }}
    >
      {children}
    </ToggleContext.Provider>
  );
};
