import { Archive, EllipsisVertical, Pencil } from 'lucide-react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '../shadcn-ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../shadcn-ui/dropdown-menu';

type ProjectMenuProps = {
  projectID: string;
};

const ProjectMenu: React.FC<ProjectMenuProps> = ({ projectID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _goToProject = useCallback(() => {
    navigate(`/project/${projectID}/edit`);
  }, [navigate, projectID]);

  const _archiveProject = useCallback(() => {
    console.log(`Archive project: ${projectID}`);
  }, [projectID]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          size="icon"
          className="max-h-[24px] max-w-[24px] focus-visible:ring-0"
        >
          <EllipsisVertical size={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-background">
        <DropdownMenuItem className="capitalize" onClick={_goToProject}>
          <Pencil size={16} />
          <span>{t('edit')}</span>
        </DropdownMenuItem>

        <DropdownMenuItem
          className="capitalize"
          onClick={_archiveProject}
          disabled
        >
          <Archive size={16} />
          <span>{t('archive')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ProjectMenu;
