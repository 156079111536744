import { EllipsisVertical, Pencil, Trash2, Wallpaper } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigationStore } from '../../../context/zustand/navigationStore';
import { useSlidesStore } from '../../../context/zustand/slidesStore';
import { useConfirmation } from '../../../hooks/useConfirmation';
import { Button } from '../../shadcn-ui/button';
import { DialogTrigger } from '../../shadcn-ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../shadcn-ui/dropdown-menu';
import SlideEditor, { EditedSlide, SlideEditorOnChange } from './SlideEditor';

const _stopEventPropagation: React.MouseEventHandler<HTMLButtonElement> = (
  e
) => {
  e.preventDefault();
};

type SlideMenuProps = {
  slideNumber: number;
  initialSlideState: EditedSlide;
  handleUpdate: (state: EditedSlide) => void;
  handleDelete: () => void;
};

const SlideMenu: React.FC<SlideMenuProps> = ({
  initialSlideState,
  slideNumber,
  handleUpdate,
  handleDelete,
}) => {
  const { t } = useTranslation();
  const { showConfirmation } = useConfirmation();

  const activeSlide = useSlidesStore((state) => state.activeSlide);
  const [editedSlide, setEditedSlide] =
    useState<EditedSlide>(initialSlideState);

  const { enableKeyboardEvents, disableKeyboardEvents } = useNavigationStore();

  const _edit: SlideEditorOnChange = useCallback(
    (key, value) => {
      if (key === 'transitionDuration') {
        const slideCopy = { ...editedSlide };
        slideCopy.json_data[0].value.view.transitionDuration = value as number;
        setEditedSlide(slideCopy);
      } else {
        setEditedSlide((prev) => ({ ...prev, [key]: value }));
      }
    },
    [editedSlide]
  );

  const _delete = useCallback(() => {
    showConfirmation(t('confirm_delete'), handleDelete);
  }, [handleDelete, t, showConfirmation]);

  const _cancel = useCallback(() => {
    enableKeyboardEvents();
    setEditedSlide(initialSlideState);
  }, [enableKeyboardEvents, initialSlideState]);

  const _update = useCallback(() => {
    enableKeyboardEvents();
    handleUpdate(editedSlide);
  }, [enableKeyboardEvents, handleUpdate, editedSlide]);

  return (
    <SlideEditor
      slideNumber={slideNumber}
      slide={editedSlide}
      onSlideChange={_edit}
      onCancel={_cancel}
      onSubmit={_update}
      submitLabel="update"
    >
      {/* NOTE SlideEditor is a Dialog that wraps DropdownMenu -> Dialog trigger can be found below */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="light max-h-[24px] text-accent hover:bg-inherit hover:text-secondary focus-visible:ring-0" // NOTE enforced light theme
            onClick={_stopEventPropagation}
          >
            <EllipsisVertical size={24} />
          </Button>
        </DropdownMenuTrigger>
        {/* NOTE:className='light' enforces the light theme! */}
        <DropdownMenuContent className="light mx-5 mt-3 rounded-lg shadow-2xl">
          {/* NOTE: Dialog trigger for SlideEditor here! */}
          <DialogTrigger asChild>
            <DropdownMenuItem
              className="capitalize"
              onClick={disableKeyboardEvents}
            >
              <Pencil size={16} />
              <span>{t('edit')}</span>
            </DropdownMenuItem>
          </DialogTrigger>
          <DropdownMenuItem
            className="capitalize"
            onClick={_update}
            disabled={activeSlide.id !== editedSlide.id}
          >
            <Wallpaper size={16} />
            <span>{t('update')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem className="capitalize" onClick={_delete}>
            <Trash2 size={16} />
            <span>{t('delete')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </SlideEditor>
  );
};

export default SlideMenu;
