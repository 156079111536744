import { PotreeDisplayModes } from '@/types';
import { ChevronDown } from 'lucide-react';

import { usePotree } from '../../../hooks/usePotree';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '../../shadcn-ui/collapsible';
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
} from '../../shadcn-ui/sidebar';
import SceneSlides from '../scenes/SceneSlides';

const RightSidebar = () => {
  const { potreeViewer, project } = usePotree();

  if (!potreeViewer) return null;

  const sceneSlides = project?.field_scenes ?? null;

  return (
    <Sidebar side="right" className="max-w-[270px] border-none">
      {/* <SidebarHeader></SidebarHeader> */}
      <SidebarContent>
        <Collapsible defaultOpen className="group/collapsible">
          <SidebarGroup>
            <SidebarGroupLabel asChild>
              <CollapsibleTrigger>
                Slides
                {/* TODO use different icon? I.e. not from lucide-react */}
                <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
              </CollapsibleTrigger>
            </SidebarGroupLabel>
            <CollapsibleContent>
              <SidebarGroupContent className="mt-2 p-2">
                {sceneSlides &&
                  Object.entries(sceneSlides).map(
                    ([mode, { label, scenes }]) => (
                      <SceneSlides
                        key={`scene-slide__${mode}`}
                        mode={mode as PotreeDisplayModes}
                        modeScenes={scenes}
                      />
                    )
                  )}
              </SidebarGroupContent>
            </CollapsibleContent>
          </SidebarGroup>
        </Collapsible>
      </SidebarContent>
      {/* <SidebarFooter ></SidebarFooter> */}
    </Sidebar>
  );
};

export default RightSidebar;
