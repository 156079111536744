import {
  EllipsisVertical,
  Pencil,
  Trash2,
  Wallpaper,
  ZoomIn,
  ZoomOut,
} from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSlidesStore } from '../../../context/zustand/slidesStore';
import { useAuth } from '../../../hooks/useAuth';
import { useConfirmation } from '../../../hooks/useConfirmation';
import { usePotree } from '../../../hooks/usePotree';
import {
  AnnotationPotreeObject,
  OrtoScene,
  SerializableAnnotation,
  Vector3,
} from '../../../types';
import { OrtoSceneHelper } from '../../../utils/OrtoSceneHelper';
import { blankRichText, contentfulRichText } from '../../../utils/helper';
import { Button } from '../../shadcn-ui/button';
import { DialogTrigger } from '../../shadcn-ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../shadcn-ui/dropdown-menu';
import AnnotationEditor, { AnnotationEditorOnChange } from './AnnotationEditor';
import { UpdateAnnotationArgs, updateScene } from './helpers/updateBackend';

const ExpandItem: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ZoomIn size={16} />
      <span className="capitalize">{t('expand')}</span>
    </>
  );
};
const CollapseItem: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ZoomOut size={16} />
      <span className="capitalize">{t('collapse')}</span>
    </>
  );
};

const _updateAnnotationByUpdatingScene = async (
  annotation: SerializableAnnotation,
  scene: OrtoScene,
  args: UpdateAnnotationArgs
) => {
  const sceneCopy = { ...scene };

  sceneCopy.json_data[0].value.annotations =
    sceneCopy.json_data[0].value.annotations.map((a) => {
      if (a.uuid === annotation.uuid) {
        return annotation;
      }
      return a;
    });

  updateScene(sceneCopy, args);
};

const _updateAnnotationCameraByUpdatingScene = async (
  uuid: string,
  scene: OrtoScene,
  cameraPosition: Vector3,
  args: UpdateAnnotationArgs
) => {
  const sceneCopy = { ...scene };

  sceneCopy.json_data[0].value.annotations =
    sceneCopy.json_data[0].value.annotations.map((a) => {
      if (a.uuid === uuid) {
        a.cameraPosition = cameraPosition.toArray();
      }
      return a;
    });

  updateScene(sceneCopy, args);
};

const _deleteAnnotationByUpdatingScene = async (
  uuid: string,
  scene: OrtoScene,
  args: UpdateAnnotationArgs
) => {
  const sceneCopy = { ...scene };

  sceneCopy.json_data[0].value.annotations =
    sceneCopy.json_data[0].value.annotations.filter((a) => a.uuid !== uuid);

  updateScene(sceneCopy, args);
};

type AnnotationMenuProps = {
  annotation: AnnotationPotreeObject;
};

const AnnotationMenu: React.FC<AnnotationMenuProps> = ({ annotation }) => {
  const { token } = useAuth();
  const { t } = useTranslation();
  const { project, setProject, potreeViewer } = usePotree();
  const { showConfirmation } = useConfirmation();

  const activeSlide = useSlidesStore((state) => state.activeSlide);
  const { field_scene_type: mode, id: sceneID } = activeSlide;

  const scene = project?.field_scenes[mode]?.scenes.find(
    (scene) => scene.id === sceneID
  );

  const [editedAnnotation, setEditedAnnotation] =
    useState<SerializableAnnotation>(
      OrtoSceneHelper.createAnnotationsData(annotation)
    );

  // Toggler for expanding and collapsing the annotation description
  const [expanded, setExpanded] = useState<boolean>(
    annotation.descriptionVisible
  );
  const _toggleExpanded = useCallback(() => {
    annotation.toggleDescription();
    setExpanded(annotation.descriptionVisible);
  }, [annotation]);

  // Expand annotation description on first load
  useEffect(() => {
    setTimeout(_toggleExpanded, 1400);
  }, [_toggleExpanded]);

  const _edit: AnnotationEditorOnChange = useCallback(
    (key, value) => setEditedAnnotation((prev) => ({ ...prev, [key]: value })),
    []
  );

  const _update = useCallback(() => {
    // Update backend: send REST request and update project
    _updateAnnotationByUpdatingScene(editedAnnotation, scene!, {
      mode,
      setProject,
      token,
    });
    // Update potree: call Annotation class setters, which in addition to setting the value also manipulate the domElement
    if (annotation.description !== editedAnnotation.description) {
      // NOTE: unfortunately in the if-else statements below the calling order matters! The Potree setter for annotation.description
      // uses jQuery to manipulate the DOM. The _toggleExpanded function depends on the DOM element height to function correctly...
      if (
        blankRichText(editedAnnotation.description) &&
        annotation.descriptionVisible
      ) {
        _toggleExpanded();
        annotation.description = editedAnnotation.description;
      } else if (
        contentfulRichText(editedAnnotation.description) &&
        !annotation.descriptionVisible
      ) {
        annotation.description = editedAnnotation.description;
        _toggleExpanded();
      } else {
        annotation.description = editedAnnotation.description;
      }
    }
    if (annotation.title !== editedAnnotation.title) {
      annotation.title = editedAnnotation.title;
    }
  }, [
    editedAnnotation,
    scene,
    mode,
    setProject,
    token,
    annotation,
    _toggleExpanded,
  ]);

  const _updateCamera = useCallback(() => {
    // Update backend: send REST request and update project
    const cameraPosition: Vector3 =
      potreeViewer.scene.getActiveCamera().position;
    _updateAnnotationCameraByUpdatingScene(
      annotation.uuid,
      scene!,
      cameraPosition,
      {
        mode,
        setProject,
        token,
      }
    );
    // Update potree: set value for Annotation class
    annotation.cameraPosition = cameraPosition;
  }, [annotation, scene, mode, setProject, token, potreeViewer]);

  const _cancel = () => {
    setEditedAnnotation(OrtoSceneHelper.createAnnotationsData(annotation));
  };

  const _delete = useCallback(() => {
    showConfirmation(t('confirm_delete'), () => {
      // Update backend: send REST request and update project
      _deleteAnnotationByUpdatingScene(annotation.uuid, scene!, {
        mode,
        setProject,
        token,
      });
      // Update potree: remove the annotation from the scene
      potreeViewer.scene.removeAnnotation(annotation);
    });
  }, [
    scene,
    mode,
    setProject,
    token,
    potreeViewer,
    annotation,
    showConfirmation,
    t,
  ]);

  // NOTE: hack for fixing update problem (when the project scenes change, it currently can happen that the activeSlide points to a non-existent scene)
  if (!scene) {
    return null;
  }

  return (
    <AnnotationEditor
      annotation={editedAnnotation}
      submitLabel="update"
      onChange={_edit}
      onSubmit={_update}
      onCancel={_cancel}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size="icon"
            className="max-h-[24px] max-w-[24px] focus-visible:ring-0"
          >
            <EllipsisVertical size={16} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            disabled={blankRichText(annotation.description)}
            onClick={_toggleExpanded}
          >
            {expanded ? <CollapseItem /> : <ExpandItem />}
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          {/* NOTE: Dialog trigger for AnnotationEditor here! */}
          <DialogTrigger asChild>
            <DropdownMenuItem className="capitalize">
              <Pencil size={16} />
              <span>{t('edit')}</span>
            </DropdownMenuItem>
          </DialogTrigger>
          <DropdownMenuItem onClick={_updateCamera}>
            <Wallpaper size={16} />
            <span>{t('set_camera')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem className="capitalize" onClick={_delete}>
            <Trash2 size={16} />
            <span>{t('delete')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </AnnotationEditor>
  );
};

export default AnnotationMenu;
