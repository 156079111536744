import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { NewSlide } from '../../../context/zustand/slidesStore';
import { OrtoScene, RichText } from '../../../types';
import { toRounded } from '../../../utils/helper';
import { Button } from '../../shadcn-ui/button';
import { Checkbox } from '../../shadcn-ui/checkbox';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../shadcn-ui/dialog';
import { Input } from '../../shadcn-ui/input';
import { Label } from '../../shadcn-ui/label';
import RichTextEditor from '../../shared/RichTextEditor';

export type EditedSlide = NewSlide & Partial<Pick<OrtoScene, 'id'>>;

export type SlideEditorOnChange = (
  key: 'label' | 'field_description' | 'transitionDuration',
  value: string | RichText | number
) => void;

type SlideEditorProps = {
  slideNumber: number;
  slide: EditedSlide;
  onSlideChange: SlideEditorOnChange;
  submitLabel: 'create' | 'update';
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};

const SlideEditor: React.FC<SlideEditorProps> = ({
  children,
  slideNumber,
  slide,
  onSlideChange,
  onSubmit,
  submitLabel,
  onCancel,
}) => {
  const { t } = useTranslation();

  const _setSlideLabel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSlideChange('label', e.target.value);
    },
    [onSlideChange]
  );

  const _setSlideDescription = useCallback(
    (value: string) => {
      onSlideChange('field_description', { value, format: 'full_html' });
    },
    [onSlideChange]
  );

  const transitionDurationChecked =
    slide.json_data[0]?.value?.view?.transitionDuration > 0;
  const _toggleTransitionDuration = useCallback(
    (checked: boolean) => {
      onSlideChange('transitionDuration', checked ? 2000 : 0);
    },
    [onSlideChange]
  );

  const _location = useCallback(() => {
    if (!slide.json_data || !slide.json_data[0]) {
      return 'unknown';
    }
    const joined = slide.json_data[0]?.value.view.position
      .map(toRounded)
      .join(', ');
    return `(${joined})`;
  }, [slide.json_data]);

  return (
    <Dialog>
      {/* Dialog trigger */}
      {children}

      {/* Dialog content - NOTE: className='light' enforces the light theme! */}
      <DialogContent className="light" aria-describedby="">
        <DialogHeader>
          <DialogTitle className="flex gap-5 font-normal text-accent">
            <span>{`#${slideNumber}`}</span>
            <span>{`Camera location: ${_location()}`}</span>
          </DialogTitle>
        </DialogHeader>

        <div className="grid gap-3 py-3">
          {/* Slide label */}
          <Input
            value={slide.label}
            onChange={_setSlideLabel}
            placeholder={`${t('title')}...`}
            className="bg-editor font-medium text-editor-foreground placeholder:capitalize"
          />

          {/* Slide description */}
          <RichTextEditor
            value={slide.field_description?.value}
            onChange={_setSlideDescription}
          />

          {/* Transition duration */}
          <div className="flex items-center gap-2">
            <Checkbox
              id="slideEditor_transitionDuration"
              checked={transitionDurationChecked}
              onCheckedChange={_toggleTransitionDuration}
            />
            <Label
              htmlFor="slideEditor_transitionDuration"
              className="font-normal"
            >
              {t('slides_enable_transition')}
            </Label>
          </div>
        </div>

        <DialogFooter className="flex gap-4">
          <DialogClose asChild>
            <Button onClick={onCancel} variant="outline" className="capitalize">
              {t('cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              onClick={onSubmit}
              className="bg-primary capitalize text-primary-foreground"
            >
              {t(submitLabel)}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SlideEditor;
