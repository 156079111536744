import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAnnotationsStore } from '../../../context/zustand/annotationsStore';
import { AnnotationPotreeObject } from '../../../types';
import AnnotationMenu from './AnnotationMenu';

type AnnotationPortalProps = {
  annotation: AnnotationPotreeObject;
};

const AnnotationPortal: React.FC<AnnotationPortalProps> = ({ annotation }) => {
  // NOTE: keeping the DOM element in the state instead of using a reference to ensure re-rendering
  // (the DOM element is added dynamically and is therefore not immediately present)
  const [domElement, setDomElement] = useState<Element | null>(null);

  useEffect(() => {
    // TODO simplify the annotation id format to just uuid?
    const htmlID = `annotation_${annotation.uuid}`;
    setDomElement(
      document
        .getElementById(htmlID)
        ?.getElementsByClassName('annotation-menu')[0] ?? null
    );
  }, [annotation.uuid]);

  return (
    <>
      {domElement !== null &&
        createPortal(
          <AnnotationMenu annotation={annotation} />,
          domElement,
          annotation.uuid
        )}
    </>
  );
};

const AnnotationPortals = () => {
  const annotations = useAnnotationsStore((state) => state.annotations);
  return (
    <>
      {annotations.map((annotation) => (
        <AnnotationPortal key={annotation.uuid} annotation={annotation} />
      ))}
    </>
  );
};

export default AnnotationPortals;
