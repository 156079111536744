import { create } from 'zustand';

import { OrtoScene } from '../../types';

export type ActiveSlide = Pick<OrtoScene, 'id' | 'field_scene_type'>;

export type NewSlide = Pick<
  OrtoScene,
  'label' | 'field_description' | 'json_data' | 'field_scene_type'
>;

type SlidesStateStore = {
  activeSlide: ActiveSlide;
  setActiveSlide: (slide: ActiveSlide) => void;
  resetActiveSlide: () => void;
  newSlide: NewSlide;
  setNewSlide: (slide: NewSlide) => void;
  resetNewSlide: () => void;
};

const NO_ACTIVE_SLIDE: ActiveSlide = {
  id: '-1',
  field_scene_type: 'model',
};

const EMPTY_NEW_SLIDE: NewSlide = {
  label: '',
  json_data: [],
  field_scene_type: 'model',
};

export const useSlidesStore = create<SlidesStateStore>((set) => ({
  activeSlide: NO_ACTIVE_SLIDE,
  setActiveSlide: (slide) => set(() => ({ activeSlide: slide })),
  resetActiveSlide: () => set({ activeSlide: NO_ACTIVE_SLIDE }),
  newSlide: EMPTY_NEW_SLIDE,
  setNewSlide: (slide) => set(() => ({ newSlide: slide })),
  resetNewSlide: () => set(() => ({ newSlide: EMPTY_NEW_SLIDE })),
}));
